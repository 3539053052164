<template>
	<div class="container mt-n15">
		<!--begin::Card-->
		<div class="card mb-8">
			<!--begin::Body-->
			<div class="card-body p-10">
				<!--begin::Row-->
				<div class="row">
					<div class="col-lg-3">
						<!--begin::Navigation-->
						<ul class="navi navi-link-rounded navi-accent navi-hover navi-active nav flex-column mb-8 mb-lg-0" role="tablist">
							<!--begin::Nav Item-->
							<li class="navi-item mb-2">
								<a class="navi-link" data-toggle="tab" href="#">
									<span class="navi-text text-dark-50 font-size-h5 font-weight-bold">Buying</span>
								</a>
							</li>
							<!--end::Nav Item-->
							<!--begin::Nav Item-->
							<li class="navi-item mb-2">
								<a class="navi-link active" data-toggle="tab" href="#">
									<span class="navi-text text-dark font-size-h5 font-weight-bold">Product Support</span>
								</a>
							</li>
							<!--end::Nav Item-->
							<!--begin::Nav Item-->
							<li class="navi-item mb-2">
								<a class="navi-link" data-toggle="tab" href="#">
									<span class="navi-text text-dark-50 font-size-h5 font-weight-bold">Account Management</span>
								</a>
							</li>
							<!--end::Nav Item-->
							<!--begin::Nav Item-->
							<li class="navi-item mb-2">
								<a class="navi-link" data-toggle="tab" href="#">
									<span class="navi-text text-dark-50 font-size-h5 font-weight-bold">Product Licenses</span>
								</a>
							</li>
							<!--end::Nav Item-->
							<!--begin::Nav Item-->
							<li class="navi-item mb-2">
								<a class="navi-link" data-toggle="tab" href="#">
									<span class="navi-text text-dark-50 font-size-h5 font-weight-bold">Base Installation</span>
								</a>
							</li>
							<!--end::Nav Item-->
							<!--begin::Nav Item-->
							<li class="navi-item mb-2">
								<a class="navi-link" data-toggle="tab" href="#">
									<span class="navi-text text-dark-50 font-size-h5 font-weight-bold">Project Management</span>
								</a>
							</li>
							<!--end::Nav Item-->
							<!--begin::Nav Item-->
							<li class="navi-item mb-2">
								<a class="navi-link" data-toggle="tab" href="#">
									<span class="navi-text text-dark-50 font-size-h5 font-weight-bold">Member Roles</span>
								</a>
							</li>
							<!--end::Nav Item-->
							<!--begin::Nav Item-->
							<li class="navi-item mb-2">
								<a class="navi-link" data-toggle="tab" href="#">
									<span class="navi-text text-dark-50 font-size-h5 font-weight-bold">Report Generation</span>
								</a>
							</li>
							<!--end::Nav Item-->
							<!--begin::Nav Item-->
							<li class="navi-item mb-2">
								<a class="navi-link" data-toggle="tab" href="#">
									<span class="navi-text text-dark-50 font-size-h5 font-weight-bold">Downloads</span>
								</a>
							</li>
							<!--end::Nav Item-->
						</ul>
						<!--end::Navigation-->
					</div>
					<div class="col-lg-9">
						<!--begin::Tab Content-->
						<div class="tab-content">
							<!--begin::Accordion-->
							<div class="accordion accordion-light accordion-light-borderless accordion-svg-toggle" id="faq">
								<!--begin::Item-->
								<div class="card">
									<div class="card-header" id="faqHeading1">
										<a class="card-title text-dark" data-toggle="collapse" href="#faq1" aria-expanded="true" aria-controls="faq1" role="button">
											<span class="svg-icon svg-icon-primary">
												<i class="fas fa-angle-double-down"></i>
											</span>
											<div class="card-label text-dark pl-4">How does it work?</div>
										</a>
									</div>
									<div id="faq1" class="collapse show" aria-labelledby="faqHeading1" data-parent="#faq">
										<div class="card-body text-dark-50 font-size-lg pl-12">Anim pariatur cliche reprehenderit, enim eiusmod high life acc</div>
									</div>
								</div>
								<!--end::Item-->
								<!--begin::Item-->
								<div class="card border-top-0">
									<!--begin::Header-->
									<div class="card-header" id="faqHeading2">
										<a class="card-title collapsed text-dark" data-toggle="collapse" href="#faq2" aria-expanded="false" aria-controls="faq2" role="button">
											<span class="svg-icon svg-icon-primary">
												<i class="fas fa-angle-double-down"></i>
											</span>
											<div class="card-label text-dark pl-4">Do I need a designer to use Metronic?</div>
										</a>
									</div>
									<!--end::Header-->
									<!--begin::Body-->
									<div id="faq2" class="collapse" aria-labelledby="faqHeading2" data-parent="#faq">
										<div class="card-body text-dark-50 font-size-lg pl-12"> ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
									</div>
									<!--end::Body-->
								</div>
								<!--end::Item-->
								<!--begin::Item-->
								<div class="card border-top-0">
									<!--begin::Header-->
									<div class="card-header" id="faqHeading3">
										<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq3" aria-expanded="false" aria-controls="faq3" role="button">
											<span class="svg-icon svg-icon-primary">
												<i class="fas fa-angle-double-down"></i>
											</span>
											<div class="card-label text-dark pl-4">What do I need to do to start selling?</div>
										</div>
									</div>
									<!--end::Header-->
									<!--begin::Body-->
									<div id="faq3" class="collapse" aria-labelledby="faqHeading3" data-parent="#faq">
										<div class="card-body text-dark-50 font-size-lg pl-12">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. ore sustainable VHS.</div>
									</div>
									<!--end::Body-->
								</div>
								<!--end::Item-->
								<!--begin::Item-->
								<div class="card border-top-0">
									<!--begin::Header-->
									<div class="card-header" id="faqHeading4">
										<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq4" aria-expanded="false" aria-controls="faq4" role="button">
											<span class="svg-icon svg-icon-primary">
												<i class="fas fa-angle-double-down"></i>
											</span>
											<div class="card-label text-dark pl-4">What happens when I receive an order?</div>
										</div>
									</div>
									<!--end::Header-->
									<!--begin::Body-->
									<div id="faq4" class="collapse" aria-labelledby="faqHeading4" data-parent="#faq">
										<div class="card-body text-dark-50 font-size-lg pl-12">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</div>
									</div>
									<!--end::Body-->
								</div>
								<!--end::Item-->
							</div>
							<!--end::Accordion-->
						</div>
						<!--end::Tab Content-->
					</div>
				</div>
				<!--end::Row-->
			</div>
			<!--end::Body-->
		</div>
		<!--end::Item-->
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import {SET_ACTION} from "@/core/services/store/actions.module";

export default {
	name: 'HelpCenter',
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.CustomerCenter.title'),
			},
			{ title: this.$t('VIEWS.CustomerCenter.Dashboard.title') },
		]);
	},
	methods: {

	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
